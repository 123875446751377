@font-face {
    font-family: 'Somatic';
    src: url('./Somatic-Rounded.otf') format('opentype');
  }

  @font-face {
    font-family: 'Geist-Black';
    src: url('./Geist-Black.otf') format('opentype');
  }
  
/* src/fonts/fonts.css */
@font-face {
    font-family: 'Geist-Bold';
    src: url('./Geist-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'Geist-ExtraBold';
    src: url('./Geist-ExtraBold.otf') format('opentype');
  }

  @font-face {
    font-family: 'Geist-ExtraLight';
    src: url('./Geist-ExtraLight.otf') format('opentype');
  }

  @font-face {
    font-family: 'Geist-Light';
    src: url('./Geist-Light.otf') format('opentype');
  }

  @font-face {
    font-family: 'Geist-Medium';
    src: url('./Geist-Medium.otf') format('opentype');
  }

  @font-face {
    font-family: 'Geist-Regular';
    src: url('./Geist-Regular.otf') format('opentype');
  }

  @font-face {
    font-family: 'Geist-SemiBold';
    src: url('./Geist-SemiBold.otf') format('opentype');
  }

  @font-face {
    font-family: 'Geist-Thin';
    src: url('./Geist-Thin.otf') format('opentype');
  }

  @font-face {
    font-family: 'GeistMono-Black';
    src: url('./GeistMono-Black.otf') format('opentype');
  }

  @font-face {
    font-family: 'GeistMono-Bold';
    src: url('./GeistMono-Bold.otf') format('opentype');
  }

  @font-face {
    font-family: 'GeistMono-Light';
    src: url('./GeistMono-Light.otf') format('opentype');
  }

  @font-face {
    font-family: 'GeistMono-Medium';
    src: url('./GeistMono-Medium.otf') format('opentype');
  }

  @font-face {
    font-family: 'GeistMono-Regular';
    src: url('./GeistMono-Regular.otf') format('opentype');
  }

  @font-face {
    font-family: 'GeistMono-SemiBold';
    src: url('./GeistMono-SemiBold.otf') format('opentype');
  }

  @font-face {
    font-family: 'GeistMono-Thin';
    src: url('./GeistMono-Thin.otf') format('opentype');
  }

  @font-face {
    font-family: 'GeistMono-UltraBlack';
    src: url('./GeistMono-UltraBlack.otf') format('opentype');
  }

  @font-face {
    font-family: 'GeistMono-UltraLight';
    src: url('./GeistMono-UltraLight.otf') format('opentype');
  }